require('bootstrap/dist/css/bootstrap.css')
require('magnific-popup/dist/magnific-popup.css')
require('suitcss-utils-flex')
require('../css/style.css')

const $ = require('jquery')
window.jQuery = $

require('jquery.backstretch')
require('magnific-popup')

const images = [
  {
    sm: require('../images/backgrounds/bg2-sm.webp'),
    md: require('../images/backgrounds/bg2-lg.webp'),
  },
]
const size = matchMedia('(max-width: 650px)').matches ? 'sm' : 'md'

$('body').backstretch([images[0][size]], {duration: 10000, fade: 0})

$('.gallery-item').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
  },
})

$('.preview-item').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
  },
})
